export const environment = {
  //apiUrl: 'https://api-br-daniel.internal.sparbankensyd.se/api',
  apiUrl: '/api',
  authServiceUrl: 'https://spb-common-api-daniel.internal.sparbankensyd.se/service',
  //authServiceUrl: '/service',
  //rtUrl: 'https://api-rt-daniel.internal.sparbankensyd.se/interests',
  //rtUrl: 'https://br-daniel.internal.sparbankensyd.se/interests',
  rtUrl: '/interests',
  domain: 'br-daniel.internal.sparbankensyd.se',
  // datastoreUrl: 'https://api-datastore-daniel.internal.sparbankensyd.se/kalp',
  datastoreUrl: '/kalp',
  testMode: true,
  production: false
}
