import {Applicant, Income, KalpDataItem, KalpItemType, Loan, Property} from '@sparbanken-syd/kalpylator'
import {SaveDataApplicant, SaveDataIncome} from '../services/data.service'
import {KalpApplicant, KalpIncome, ObjectTypes} from '../services/kalp.service'
import {PropertyType} from '@sparbanken-syd/kalpylator/types/public'
import {BuildingTypes, KalpBuilding} from './kalp-building'
import {KalpLoan} from './kalp-loan'

export class KalpylatorHelper {

  public static convertPropertyTypeToLegacy(type: PropertyType): ObjectTypes {
    const map: Map<PropertyType, string> = new Map<PropertyType, string>([
      ['VILLA', 'Villa'],
      ['BOSTADSRATT', 'Bostadsrätt'],
      ['FRITIDSHUS', 'Fritidshus'],
      ['HYRES', 'Hyresrätt']
    ])
    return map.get(type) as ObjectTypes
  }

  public static convertPropertyTypeToNg(type: ObjectTypes): PropertyType {
    const map: Map<string, PropertyType> = new Map<string, PropertyType>([
      ['Villa', 'VILLA'],
      ['Bostadsrätt', 'BOSTADSRATT'],
      ['Fritidshus', 'FRITIDSHUS'],
      ['Hyresrätt', 'HYRES']
    ])
    return map.get(type) as PropertyType
  }

  /**
   * A helper that gets all values of type T from an array of mixed types.
   * This should probably live in the Kalpylator
   *
   * @param kalpItems - An array of KalpItems
   * @param type - Like 'applicants', 'loans'  etc.
   */
  public static getKalpItems<T>(kalpItems: KalpDataItem[], type: KalpItemType): T[] {
    return kalpItems.filter((kalpItem: KalpDataItem) => kalpItem.type === type) as T[]
  }

  public static convertApplicantToLegacy<T>(storedApplicant: Applicant): T {
    const applicant: SaveDataApplicant = {
      personNummer: storedApplicant.sub ?? '',
      name: storedApplicant.name ?? '', //  Name is not mandatory in DataStore,
      retired: storedApplicant.retired,
      reducedIncome: storedApplicant.reducedIncome
    }
    /**
     * We can have multiple spouses in DataStore but only one in BR
     */
    if (storedApplicant.spouse) {
      applicant.hasSpouse = true
      applicant.spouse = storedApplicant.spouse[0]
    }

    // We must not overwrite the employer if not in the data set.
    if (storedApplicant.employer) {
      applicant.employer = storedApplicant.employer
    }

    // Nor A-Kassa
    if (storedApplicant.akassa) {
      applicant.akassa = true
    }

    // Or spouse for that matter
    if (storedApplicant.spouse) {
      applicant.hasSpouse = true
      applicant.spouse = storedApplicant.spouse[0]
    }

    return applicant as T
  }


  public static convertIncomeToLegacyResult(income: Required<Income>): KalpIncome {
    return {
      column: income.taxColumn,
      income: income.monthlyReducedIncome ?? income.monthlyIncome,
      net: income.monthlyNetIncome,
      otherCosts: income.otherCosts,
      studyLoan: income.studyLoan,
      tax: income.monthlyTax,
      taxFreeIncome: income.taxFreeIncome
    }
  }

  public static convertIncomeToLegacy<T>(storedIncome: Income): T {
    const res: SaveDataIncome = {income: 0, studyLoan: 0, taxFreeIncome: 0}
    res.income = storedIncome.monthlyIncome
    res.studyLoan = storedIncome.studyLoan ?? 0
    res.taxFreeIncome = storedIncome.taxFreeIncome
    return res as T
  }

  /**
   * From old to new
   */
  public static convertApplicantToNG(a: KalpApplicant, index: number): Applicant {
    const applicant: Applicant = {
      type: 'applicants',
      id: index + '',
      cityDweller: false,
      name: a.name,
      occupationType: 'EMPLOYED',
      retired: a.retired,
      spouse: [],
      sub: a.personNummer
    }

    if (a.akassa) {
      applicant.akassa = {fee: 0}
    }

    if (a.spouse) {
      applicant.spouse = [a.spouse]
    }
    return applicant
  }

  public static convertIncomeToNG(income: KalpIncome, id: number): Income {
    return {
      type: 'incomes',
      id: id + '',
      applicantId: id + '',
      monthlyIncome: income.income,
      taxFreeIncome: income.taxFreeIncome,
      studyLoan: income.studyLoan,
      otherCosts: income.otherCosts
    }
  }

  /**
   * Loans in original KALP that has been processed are converted
   * to input for the new Kalpylator
   * @param input
   */
  public static convertKalpLoanToNG(input: KalpLoan): Loan {
    return {
      id: input.id,
      type: 'loans',
      additionalLoan: false,
      amount: input.amount,
      interestRate: input.interestPercent,
      keep: !input.solve,
      loanType: input.isBlancoLoan ? 'BLANCO' : 'MORTGAGE',
      mortgage: input.mortgageAmount,
      new: input.new,
      propertyId: input.property,
      terms: input.terms,
      expires: input.expires
    }
  }

  /**
   * Converts a Loan that has passed kalpylator to a loan that
   * has passed kalpylator
   */
  public static convertNGLoanToLegacyResult(input: Required<Loan>): KalpLoan {
    const blancoLoans = ['BLANCO', 'CREDIT', 'BORGEN']
    const loan = new KalpLoan(input.amount, input.new)
    loan.id = input.id
    loan.isBlancoLoan = blancoLoans.indexOf(input.loanType) !== -1
    loan.property = input.propertyId
    // Note that we have to calculate this backwards since the interest
    // rate may have been modified. Fix this is in Kalpylator?
    loan.interestPercent = ((input.monthlyInterest * 12) / input.amount)
    loan.setMortgageAmount(input.mortgage)
    loan.solve = !input.keep
    loan.terms = input.terms
    loan.expires = input.expires
    return loan
  }

  public static convertBuildingToNG(input: KalpBuilding): Property {
    return {
      type: 'properties',
      id: input.id,
      // TODO: What if scenario !== New Loan?
      primary: input.type === BuildingTypes.purchase,
      propertyType: KalpylatorHelper.convertPropertyTypeToNg(input.typeOfObject),
      friendlyName: input.heading,
      marketValue: input.objectValue,
      rent: input.fee,
      // TODO: Check if this is true.
      keep: input.type !== BuildingTypes.leave
    }
  }

}
