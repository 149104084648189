import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/local-storage.provider'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {ResponseInterceptor} from './application/response-interceptor'
import {AuthInterceptor} from './application/auth-interceptor'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import '@angular/common/locales/global/fr'
import {ConfigService} from './services/config.service'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core'
import {LuxonDateAdapter, MAT_LUXON_DATE_FORMATS} from '@angular/material-luxon-adapter'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {SpbCommonModule} from './common/common.module'
import {ThemeModule, SpbPaginatorSv} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatPaginatorIntl} from '@angular/material/paginator'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    ThemeModule,
    SpbCommonModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    ConfigService,
    {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => configService.checkStatus,
      deps: [ConfigService],
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
